import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../constants'

export const styles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.lightTeal,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    height: '40px',
    width: '200px',
    color: '#000000',
    fontSize: '13px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
