import { FormControl } from '@material-ui/core'
import { navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { useAppState } from '../../appState'
import { ROUTES } from '../../constants'
import { styles } from './styles'

interface BrandsSortProps {
  count: number
  selectedFilter: number
  onFilterChange: (param: number) => void
  t: TFunction
}

const CHRBrandsSort = ({
  t,
  count,
  onFilterChange,
  selectedFilter,
  ...props
}: BrandsSortProps) => {
  const [appState] = useAppState()
  const classes = styles()
  const [selectedOption, setSelectedOption] = useState(
    appState.settings.brandsView || {
      value: 1,
      label: t('shared.alphabetical'),
    }
  )

  const options = [
    { value: 1, label: t('shared.alphabetical') },
    { value: 2, label: t('shared.lowestToHighest') },
    { value: 3, label: t('shared.highestToLowest') },
  ]

  useEffect(() => {
    const url = new URL(location.href.replace('#', ''))
    const sort = url.searchParams.get('sort')
    if (sort && ['1', '2', '3'].includes(String(sort))) {
      setSelectedOption(options.find(option => option.value === Number(sort)))
      onFilterChange(Number(sort))
    }
  }, [location])

  const handleChange = event => {
    setSelectedOption(event)
    onFilterChange(event.value)
    const params = new URLSearchParams(location.search)
    if (params.has('sort')) {
      params.delete('sort')
    }
    params.append('sort', event.value)
    navigate(`${ROUTES.brands}?${params.toString()}`)
  }

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      width: '210px',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Schnyder XL Light',
        fontSize: 30,
        padding: '0 0 0 10px',
      }
    },

    valueContainer: styles => ({
      height: 50,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem',
      fontWeight: 400,
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: 250,
    }),
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>{t('shared.sortBy')}</h4>
        <Select
          defaultValue={selectedOption}
          value={selectedOption}
          className={classes.selectList}
          onChange={handleChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(CHRBrandsSort)
