import { Box, Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { isIOS, isSafari, isTablet } from 'react-device-detect'
import appIcon from '../assets/images/app-icon.svg'
import arrowBack from '../assets/images/arrow-back-header.svg'
import chirpyestLogo from '../assets/images/chirpyest-logo.svg'
import extensionIcon from '../assets/images/extension-icon.svg'
import iosPageSettings from '../assets/images/ios-page-settings.png'
import toggleIcon from '../assets/images/ios-toggle-icon.svg'
import safariAllow from '../assets/images/safari-allow-ext.png'
import OpenIosApp from '../components/openIosApp/OpenIosApp'
import { styles } from '../pagesStyles/welcome'
import WrongBrowser from './wrong-browser'

declare const window: any

const SafariPageSetting = ({ classes }: { classes: ClassNameMap }) => {
  return (
    <div className={classes.textWithIcons}>
      tap the
      <img
        src={iosPageSettings}
        alt="ios-page-settings-icon"
        height={22.5}
        width={16.5}
        className={classes.inlineIcon}
      />
      <span style={{ marginLeft: '0.5rem' }}>in the address bar</span>
    </div>
  )
}

const ExtensionWelcomePage = () => {
  const { search } = useLocation()
  const { isExtensionEnabled } = parse(search)
  const [step, setStep] = useState(isExtensionEnabled ? 1 : 0)
  const classes = styles()

  useEffect(() => {
    if (step === 1) {
      window.localStorage.setItem('appInstalled', 'true')
    }
  }, [step])

  if (isIOS && !isSafari) {
    return <WrongBrowser />
  }
  return (
    <div className={classes.container}>
      <div>
        {step !== 0 && (
          <img
            src={arrowBack}
            alt="Go Back"
            className={classes.goBack}
            onClick={() => setStep(0)}
          />
        )}
        <img
          src={chirpyestLogo}
          alt="Chirpyest Logo"
          className={classes.logo}
        />
      </div>
      <div className={classes.content}>
        {step === 0 ? (
          <>
            <Typography variant="body1" className={classes.title}>
              how to enable the chirpyest extension for safari?
            </Typography>
            <video
              playsInline
              loop
              autoPlay
              muted
              preload="none"
              className={classes.media}
            >
              <source
                src={
                  isTablet
                    ? '/videos/enable-safari-ios-tablet.mp4'
                    : '/videos/enable-safari-ios-mobile.mp4'
                }
                type="video/mp4"
              />
            </video>
            <ol className={classes.list}>
              <li className={classes.listItem}>
                <SafariPageSetting classes={classes} />
              </li>
              <li className={classes.listItem}>
                <div className={classes.textWithIcons}>
                  tap manage extensions
                  <img src={extensionIcon} className={classes.inlineIcon} />
                </div>
              </li>
              <li className={classes.listItem}>
                <div className={classes.textWithIcons}>
                  turn on chirpyest
                  <img src={appIcon} className={classes.inlineIcon} />
                  <img src={toggleIcon} className={classes.inlineIcon} />
                </div>
              </li>
            </ol>

            <OpenIosApp />
          </>
        ) : (
          <>
            <Typography variant="body1" className={classes.title}>
              now we need your permission to give you cash back
            </Typography>
            <Box mt={1.25}>
              <Typography variant="body1" className={classes.text}>
                we don’t sell or share your data, it’s in our{' '}
                <Link to="/company#privacy" className={classes.link}>
                  privacy policy
                </Link>
              </Typography>
            </Box>
            <img
              src={safariAllow}
              className={classes.media}
              alt="IOS Allow access"
            />
            <ol className={classes.list}>
              <li className={classes.listItem}>
                <SafariPageSetting classes={classes} />
              </li>
              <li className={classes.listItem}>
                tap the chirpyest extension{' '}
                <img
                  src={chirpyestLogo}
                  alt="Chirpyest Logo"
                  className={classes.extensionLogo}
                />
              </li>
              <li className={classes.listItem}>
                tap <span style={{ fontWeight: 600 }}>‘Always Allow’</span> then
                <span style={{ fontWeight: 600 }}>
                  ‘Always Allow on Every Website’
                </span>
              </li>
            </ol>
            {/* <Button
              onClick={() => {}} // TODO: add review button function
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="review"
            /> */}

            <OpenIosApp />
          </>
        )}
      </div>
    </div>
  )
}

export default ExtensionWelcomePage
