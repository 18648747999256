import {
  Card as MUIcard,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd'
import closeIcon from '../../../assets/images/close.svg'
import errorImg from '../../../assets/images/error-image.svg'
import axios from '../../../axios'
import { COLORS } from '../../../constants'
import SBcheckbox from '../../customCheckbox'
import CHRImage from '../../trendingCard/CHRImage'
import { styles } from './styles'
import ViewModuleIcon from '@material-ui/icons/ViewModule'

interface ICardProps {
  product: any
  index: number
  id: any
  moveCard: any
  isDragging: boolean
  connectDragSource: any
  connectDropTarget: any
  elGrid: any
  canEdit: boolean
  onCardClick: Function
  removeItem: Function
  showSelect: boolean
  onCheck: Function
  isFolder: boolean
  shoppingUserProfile: any
  onGridClick: Function
}

const Card = forwardRef(
  (
    {
      product,
      isDragging,
      connectDragSource,
      connectDropTarget,
      canEdit,
      onCardClick,
      removeItem,
      showSelect,
      onCheck,
      isFolder = false,
      shoppingUserProfile,
      onGridClick,
    }: ICardProps,
    ref
  ) => {
    const classes = styles()
    const elementRef = useRef(null)
    const [cheight, setCHeight] = useState(0)
    const [checked, setChecked] = useState(false)
    const theme = useTheme()
    const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [igImg, setIgImg] = useState(null)
    const [loadingImg, setLoadingImg] = useState(true)

    useEffect(() => {
      !showSelect && setChecked(false)
    }, [showSelect])

    connectDragSource(elementRef)
    connectDropTarget(elementRef)

    const opacity = isDragging ? 0 : 1

    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))

    const onCheckProduct = id => {
      onCheck(id, !checked)
      setChecked(!checked)
    }

    const handleRemoveItem = () => {
      isFolder ? removeItem(product.folderItemId) : removeItem(product.id)
    }

    const igAvatar = shoppingUserProfile
      ? `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${shoppingUserProfile.imageKey}`
      : ''

    useEffect(() => {
      ;(async () => {
        if (product.type === 'instagram') {
          try {
            let igImageUrl = product.data.url

            if (!igImageUrl) {
              let data = await axios.get(
                `/api/v1/ig?igUrl=${encodeURIComponent(
                  product.data.link + 'media/?size=l'
                )}`
              )

              igImageUrl = data.data
            }

            setIgImg(igImageUrl)
            setLoadingImg(false)
          } catch (err) {
            setIgImg(errorImg)
            setLoadingImg(false)
          }
        }
      })()
    }, [])
    const handlerClickOnGrid = (e: any, product) => {
      e.stopPropagation()
      onGridClick(product)
    }
    const price =
      isNaN(product.data.price) || String(product.data.price) === ''
        ? `${product.data.price}`
        : '$' + product.data.price.toFixed(2)
    let originalPrice =
      isNaN(product.data.originalPrice) ||
      String(product.data.originalPrice) === ''
        ? `${product.data.originalPrice}`
        : '$' + product.data.originalPrice.toFixed(2)
    originalPrice = originalPrice === '' ? price : originalPrice
    return (
      <>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={6}
          ref={elementRef}
          style={{
            cursor: canEdit && !showSelect ? 'move' : 'pointer',
            opacity,
            userSelect: 'none',
            paddingTop: 0,
            paddingBottom: 40,
          }}
          className={classes.SBCard}
        >
          <MUIcard className={classes.card} style={{ position: 'relative' }}>
            {canEdit &&
              (!showSelect ? (
                <>
                  <span
                    className={classes.btnRemove}
                    onClick={handleRemoveItem}
                  >
                    {closeIcon && (
                      <img
                        src={closeIcon}
                        className={classes.btnIcon}
                        alt="buttonIcon"
                      />
                    )}
                  </span>
                  {product.type !== 'instagram' ? (
                    <span
                      className={classes.choiceButton}
                      onClick={e => handlerClickOnGrid(e, product)}
                    >
                      {ViewModuleIcon && (
                        <ViewModuleIcon style={{ fontSize: '25.6px' }} />
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div className={classes.checkboxWrapper}>
                  <div className={classes.whiteCircle}></div>
                  <SBcheckbox
                    checked={checked}
                    onChange={onCheckProduct}
                    id={product.id.toString()}
                  />
                </div>
              ))}
            <div
              className={classes.cardArea}
              onClick={() =>
                showSelect ? onCheckProduct(product.id) : onCardClick(product)
              }
            >
              <div
                className={classes.cardMediaWrapper}
                style={{
                  height: isPhone ? '240px' : cheight === 0 ? 'auto' : cheight,
                }}
              >
                <div className={classes.cardMediaWrapperInner}>
                  {loadingImg && product.type === 'instagram' && (
                    <div className={classes.loading}>
                      <CircularProgress
                        style={{ color: COLORS.black }}
                        size={20}
                      />
                    </div>
                  )}
                  {product.type === 'product' ? (
                    <CHRImage
                      errStyle={classes.errImage}
                      alt={product.data.name}
                      src={product.data.imageUrl || product.data.base64}
                      title={product.data.name}
                      className={classes.cardMedia}
                      ref={(el: any) => {
                        if (el) {
                          if (
                            cheight < 150 &&
                            cheight < el.clientHeight &&
                            el.clientHeight > 150
                          ) {
                            setCHeight(el.clientHeight)
                          }
                        }
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      alt={product.data.name}
                      image={igImg}
                      title={product.data.name}
                      className={classes.cardMedia}
                      style={{
                        height: 'auto',
                      }}
                      ref={(el: any) => {
                        if (el) {
                          if (
                            cheight < 150 &&
                            cheight < el.clientHeight &&
                            el.clientHeight > 150
                          ) {
                            setCHeight(el.clientHeight)
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <CardContent className={classes.cardContent}>
                {product.type === 'product' ? (
                  <>
                    <div className={classes.boxInfo}>
                      <Typography component="h2" className={classes.cardName}>
                        {product.data.name}
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.cardBrandName}
                      >
                        {product.data.brandName}
                      </Typography>
                    </div>
                    <div className={classes.boxPrices}>
                      <div className={classes.saleBox}>
                        {Number(price?.slice(1)) <
                        Number(originalPrice?.slice(1)) ? (
                          <div>
                            <Typography
                              component="p"
                              className={classes.cardPrice}
                              style={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {originalPrice}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.cardPrice}
                            >
                              {price}
                            </Typography>
                          </div>
                        ) : (
                          <Typography
                            component="p"
                            className={classes.cardPrice}
                          >
                            {price}
                          </Typography>
                        )}
                      </div>
                      {product.data.cashBack && canEdit && (
                        <Typography
                          component="p"
                          className={classes.cashPercentage}
                        >
                          up to {product.data.cashBack}
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.boxInfo}>
                      <div className={classes.cardIngMediaWrap}>
                        <CHRImage
                          errStyle={classes.errIgImage}
                          src={product?.data?.profile_image || igAvatar}
                          title={product.data.username}
                          alt={product.data.username}
                          className={`${classes.cardMedia} ${classes.cardIngMediaWrap}`}
                        />
                      </div>
                      <Typography component="p" className={classes.cardIngText}>
                        @{product.data.username}
                      </Typography>
                    </div>
                  </>
                )}
              </CardContent>
            </div>
          </MUIcard>
        </Grid>
      </>
    )
  }
)

interface CardProps {
  id: any
  index: number
  moveCard: any
  isDragging: boolean
  connectDragSource: ConnectDragSource
  connectDropTarget: ConnectDropTarget
  handleUpdatePosition: any
  product: any
  canEdit: boolean
  showSelect: boolean
}

interface CardInstance {
  getNode(): HTMLDivElement | null
}

interface CardDragObject {
  id: string
  index: number
}

const scrollWhileDrag = (monitor: DragSourceMonitor) => {
  const p: any = monitor.getClientOffset().y
  const top25 = window.innerHeight / 4
  const bottom25 = (window.innerHeight / 4) * 3
  const speed = 7
  if (p <= top25) {
    window.scroll({
      top: Math.max(200, window.scrollY - speed),
    })
  } else if (p >= bottom25) {
    window.scroll({
      top: window.scrollY + speed,
    })
  }
}

const DraggableItem = DropTarget(
  'card',
  {
    hover(
      props: CardProps,
      monitor: DropTargetMonitor,
      component: CardInstance
    ) {
      const dragIndex = monitor.getItem<CardDragObject>().index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      if (!monitor.isOver({ shallow: true })) return

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)

      monitor.getItem<CardDragObject>().index = hoverIndex
    },
    drop: ({ id, product, index, handleUpdatePosition }: CardProps) => {
      handleUpdatePosition(
        id,
        product.folderItemPosition || product.position,
        index
      )
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    'card',
    {
      beginDrag: (props: CardProps, monitor: DragSourceMonitor) => {
        //@ts-ignore
        monitor.scrollTimer = setInterval(() => scrollWhileDrag(monitor), 1)
        return {
          id: props.id,
          index: props.index,
          props: props,
        }
      },
      endDrag: (props: CardProps, monitor: DragSourceMonitor) => {
        //@ts-ignore
        clearInterval(monitor.scrollTimer)
      },
      canDrag: (props: CardProps) => props.canEdit && !props.showSelect,
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Card)
)

export default DraggableItem
