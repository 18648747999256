import { CircularProgress } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Dialog from '@material-ui/core/Dialog'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TFunction } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { isIOS, isMacOs } from 'react-device-detect'
import ReactHtmlParser from 'react-html-parser'
import { withTranslation } from 'react-i18next'
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'
import { generateTrackingLink } from '../../../../utils/brandsHelper'
import { useAppState } from '../../../appState'
import axios from '../../../axios'
import { baseURL, ENDPOINTS, printestDefaultImageURL } from '../../../constants'
import CHRButton from '../../button'
import ChooseImage from '../../chooseImage'
import CHRInput from '../../input'
import SocialShareLink from '../../SocialShareLink'
import CHRImage from '../../trendingCard/CHRImage'
import closeMenuIcon from './../../../assets/images/close.svg'
import emailIcon from './../../../assets/images/dark-email.svg'
import facebookIcon from './../../../assets/images/facebook.svg'
import pinterestIcon from './../../../assets/images/pinterest.svg'
import smsIcon from './../../../assets/images/text.svg'
import twiterIcon from './../../../assets/images/twitter.svg'
import { styles } from './styles'

interface SignInModalProps {
  handleClose: any
  openStatus: boolean
  selectedItem: any
  canEdit: boolean
  handleUpdateShortLink: Function
  handleChangeImage: Function
  t: TFunction
}

const quickViewModal = ({
  handleClose,
  openStatus,
  selectedItem,
  canEdit,
  handleUpdateShortLink,
  handleChangeImage,
  t,
}: SignInModalProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const textAreaRef = useRef(null)
  const [currentMessage, setCurrentMessage] = useState({
    text: '',
    error: false,
  })
  const [subject, setSubject] = useState('I want to share this link with you ')
  const [appState] = useAppState()
  const [shortLink, setShortLink] = useState(null)
  const [isShortLinkLoading, setIsShortLinkLoading] = useState(false)
  const [showImages, setShowImages] = useState(false)
  const [carouselImages, setCarouselImages] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  const [error, setError] = useState('')
  const [completeTrackingUrl, setCompleteTrackingUrl] = useState(null)
  useEffect(() => {
    selectedItem && setShortLink(selectedItem.data.shortLink)
    selectedItem && setCompleteTrackingUrl(selectedItem.data.tracking_url)
  }, [selectedItem])

  const copyToClipboard = (event: any) => {
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
      const successMessage = t('messages.copiedToClipboard')
      setCurrentMessage({ text: successMessage, error: false })
    } else {
      const failMessage = t('messages.couldNotSelectText')
      setCurrentMessage({ text: failMessage, error: false })
    }
  }

  const handleSMSButtonClick = event => {
    if (selectedItem.data.shortLink || shortLink) {
      if (isIOS || isMacOs) {
        window.open(
          `sms:?&body=${t('createLink.smsMessage')} ${
            selectedItem.data.shortLink
              ? selectedItem.data.shortLink
              : shortLink
          }`,
          '_self'
        )
      } else {
        window.open(
          `sms:?body=${t('createLink.smsMessage')} ${
            selectedItem.data.shortLink
              ? selectedItem.data.shortLink
              : shortLink
          }`,
          '_self'
        )
      }
    }
  }

  const handleCreateShortLink = async () => {
    try {
      setIsShortLinkLoading(true)
      let refinedURL
      let productURL = new URL(selectedItem.data.link)
      if (
        productURL.host === 'www.containerstore.com' &&
        productURL.search.includes('q=')
      ) {
        productURL.search.split('&').forEach(param => {
          if (param.includes('productId')) {
            refinedURL = productURL.origin + productURL.pathname + '?' + param
          }
        })
      } else {
        refinedURL = selectedItem.data.link
      }
      const shortenedValue = await axios.post(
        `${baseURL}/${ENDPOINTS.shortenLink}`,
        {
          url: refinedURL,
          productId: selectedItem.productId,
          shoppingItemId: selectedItem.id,
          productImage: selectedItem.data.imageUrl,
          productName: selectedItem.data.name,
        }
      )
      const {
        data: {
          data: { shortUrl, trackingLink },
        },
      } = shortenedValue
      setShortLink(shortUrl)
      setCompleteTrackingUrl(trackingLink)
      setIsShortLinkLoading(false)
      handleUpdateShortLink(selectedItem, shortUrl, trackingLink)
    } catch (err) {
      setIsShortLinkLoading(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const trackingLinkNoRedirect = generateTrackingLink(
    {
      network: selectedItem.data.networkId,
      trackingLink: selectedItem.data.tracking_url,
      url: selectedItem.data.link,
      brandName: selectedItem.data.brandName,
      commission: selectedItem.data.cashBack,
    },
    {
      userId: `${appState.userId || 0}`,
      userName: appState.userProfile?.userName,
    },
    selectedItem.data.productId,
    selectedItem.id,
    false
  )

  const handleShopButtonClick = () => {
    if (selectedItem.type === 'instagram') {
      window.open(selectedItem.link, '_blank')
    } else {
      let link = generateTrackingLink(
        {
          network: selectedItem.data.networkId,
          trackingLink: selectedItem.data.tracking_url,
          url: selectedItem.data.link,
          brandName: selectedItem.data.brandName,
          commission: selectedItem.data.cashBack,
        },
        {
          userId: `${appState.userId || 0}`,
          userName: appState.userProfile?.userName,
        },
        selectedItem.data.productId,
        selectedItem.id,
        canEdit
      )
      window.open(link, '_blank')

      // we don't want to wait for this api call
      axios
        .post(
          `/api/v1/shopping-board-items/${selectedItem.id}/update-clicks-count`
        )
        .catch(() => {})
    }
  }

  const handleChoice = async () => {
    try {
      setLoadingImages(true)
      setShowImages(true)

      let {
        data: { data },
      } = await axios.post(ENDPOINTS.getProductAllimages, {
        productLink: selectedItem.data.link,
      })
      data = data.filter((a: string) => !a.startsWith('data:'))
      setCarouselImages(data)
      setLoadingImages(false)
      if (data.length === 0) {
        setShowImages(false)
      }
    } catch (err) {
      setLoadingImages(false)
      setShowImages(false)
    }
  }

  const handleUpdateImage = async (updatedImg: string) => {
    try {
      setLoadingImages(true)
      setShowImages(false)
      await axios.post(
        `/api/v1/shopping-board-items/${selectedItem.id}/update-item-image`,
        {
          data: { ...selectedItem.data, imageUrl: updatedImg },
        }
      )
      handleChangeImage(selectedItem.id, updatedImg)
      setLoadingImages(false)
    } catch (e) {
      setLoadingImages(false)
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="quickview"
        className={classes.modal}
      >
        <section className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          {selectedItem && (
            <Card className={classes.card}>
              <CardActionArea className={classes.cardArea}>
                <div className={classes.cardMediaWrapper}>
                  {loadingImages ? (
                    <div className={classes.loadingImages}>
                      <CircularProgress size={40} />
                    </div>
                  ) : !showImages ? (
                    <div className={classes.imageWrapper}>
                      <CHRImage
                        alt={selectedItem.data.name || selectedItem.data.igName}
                        src={
                          selectedItem.data.imageUrl || selectedItem.data.base64
                        }
                        title={
                          selectedItem.data.name || selectedItem.data.igName
                        }
                        className={classes.cardMedia}
                        errStyle={classes.errImage}
                      />
                      {/* <button
                        onClick={handleChoice}
                        className={classes.choiceButton}
                      >
                        <ViewModuleIcon fontSize="large" />
                      </button> */}
                    </div>
                  ) : (
                    canEdit && (
                      <ChooseImage
                        currentImage={
                          selectedItem.data.imageUrl || selectedItem.data.base64
                        }
                        images={carouselImages}
                        handleUpdate={handleUpdateImage}
                      />
                    )
                  )}
                </div>
                <CardContent className={classes.cardContent}>
                  {selectedItem.type === 'product' ? (
                    <>
                      <div className={classes.boxInfo}>
                        <Typography component="h2" className={classes.cardName}>
                          {selectedItem.data.name}
                        </Typography>
                      </div>
                      <div className={classes.boxPrices}>
                        <Typography
                          component="p"
                          className={classes.cardBrandName}
                        >
                          by{' '}
                          <span className={classes.cardBrandNameText}>
                            {selectedItem.data.brandName}
                          </span>
                        </Typography>
                        <Typography component="p" className={classes.cardPrice}>
                          {selectedItem.data.price}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.igBox}>
                        <div className={classes.igBoxMediaWrap}>
                          <CardMedia
                            component="img"
                            alt={selectedItem.data.igName}
                            image={selectedItem.data.avatarBase64}
                            title={selectedItem.data.igName}
                            className={classes.igBoxMedia}
                          />
                        </div>
                        <div className={classes.igBoxInfo}>
                          <Typography
                            component="p"
                            className={classes.igBoxText}
                          >
                            {selectedItem.type === 'product' ? '@' : ''}
                            {selectedItem.data.igName}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                  <div className={classes.shareBox}>
                    {selectedItem.type === 'product' && canEdit && (
                      <Typography
                        component="p"
                        className={classes.cashPercentage}
                      >
                        {ReactHtmlParser(t('createLink.createdSubtitle1'))}{' '}
                        {selectedItem.data.cashBack}{' '}
                        {ReactHtmlParser(t('createLink.createdSubtitle2'))}
                      </Typography>
                    )}
                    <div className={classes.formShare}>
                      {shortLink ? (
                        <div
                          className={`${classes.inputContainer} ${classes.convertedLink}`}
                        >
                          <CHRInput
                            placeholder=""
                            value={shortLink}
                            elementRef={textAreaRef}
                            customStyles={classes.input}
                          />
                          {document.queryCommandSupported('copy') && (
                            <CHRButton
                              label={t('shoppingBoard.copyBtn')}
                              onClick={copyToClipboard}
                              customStyle={classes.btnCopy}
                            />
                          )}
                        </div>
                      ) : (
                        <button
                          className={`${
                            classes.shopLinkBtn
                          } ${isShortLinkLoading && classes.disabledBtn}`}
                          onClick={handleCreateShortLink}
                          disabled={isShortLinkLoading}
                        >
                          {t('createLink.createShareLink')}
                        </button>
                      )}
                      <div className={classes.shopLinkWrap}>
                        <span
                          className={classes.shopLinkBtn}
                          onClick={handleShopButtonClick}
                        >
                          {t('createLink.shopLink')}
                        </span>
                      </div>
                      {error && <p className={classes.errMsg}>{error}</p>}
                    </div>
                    <div className={classes.viaSocials}>
                      <Typography component="p" className={classes.viaShort}>
                        {t('shoppingBoard.shareVia')}
                      </Typography>
                      <div className={classes.viaLinks}>
                        <div className={`${classes.socialButton}`}>
                          <SocialShareLink
                            onInitial={() => {
                              setShortLink('')
                              setIsShortLinkLoading(true)
                            }}
                            onSuccess={data => {
                              setShortLink(data.shortUrl)
                              setIsShortLinkLoading(false)
                              handleUpdateShortLink(
                                selectedItem,
                                data.shortUrl,
                                data.tracking_url
                              )
                            }}
                            shortenedLink={shortLink}
                            shortLinkData={{
                              url: selectedItem.data.link,
                              productId: selectedItem.productId,
                              shoppingItemId: selectedItem.id,
                              productImage: selectedItem.data.imageUrl,
                              productName: selectedItem.data.name,
                            }}
                            isLoggedIn
                          >
                            {ref => (
                              <>
                                <button
                                  ref={ref}
                                  onClick={handleSMSButtonClick}
                                  style={{
                                    display: 'none',
                                  }}
                                >
                                  <img
                                    src={smsIcon}
                                    alt={t('shared.sms')}
                                    className={classes.socialIcon}
                                  />
                                </button>
                                <button className={classes.smsButton}>
                                  <img
                                    src={smsIcon}
                                    alt={t('shared.sms')}
                                    className={classes.socialIcon}
                                  />
                                </button>
                              </>
                            )}
                          </SocialShareLink>
                        </div>

                        <div
                          className={`${classes.socialButton} ${classes.socialShareButton}`}
                        >
                          <SocialShareLink
                            onInitial={() => {
                              setShortLink('')
                              setIsShortLinkLoading(true)
                            }}
                            onSuccess={data => {
                              setShortLink(data.shortUrl)
                              setIsShortLinkLoading(false)
                              handleUpdateShortLink(
                                selectedItem,
                                data.shortUrl,
                                data.tracking_url
                              )
                            }}
                            shortenedLink={shortLink}
                            shortLinkData={{
                              url: selectedItem.data.link,
                              productId: selectedItem.productId,
                              shoppingItemId: selectedItem.id,
                              productImage: selectedItem.data.imageUrl,
                              productName: selectedItem.data.name,
                            }}
                            isLoggedIn
                          >
                            {(ref, url) => (
                              <>
                                <EmailShareButton
                                  ref={ref}
                                  subject={t('createLink.emailSubject')}
                                  body={t('createLink.emailBody')}
                                  url={url}
                                  style={{
                                    display: 'none',
                                  }}
                                >
                                  <img
                                    src={emailIcon}
                                    alt={t('shared.email')}
                                    className={classes.socialIcon}
                                  />
                                </EmailShareButton>
                                <img
                                  src={emailIcon}
                                  alt={t('shared.email')}
                                  className={classes.socialIcon}
                                />
                              </>
                            )}
                          </SocialShareLink>
                        </div>
                        <div
                          style={{ marginLeft: 10 }}
                          className={`${classes.socialButton} ${classes.socialShareButton}`}
                        >
                          <SocialShareLink
                            onInitial={() => {
                              setShortLink('')
                              setIsShortLinkLoading(true)
                            }}
                            onSuccess={data => {
                              setShortLink(data.shortUrl)
                              setIsShortLinkLoading(false)
                              handleUpdateShortLink(
                                selectedItem,
                                data.shortUrl,
                                data.tracking_url
                              )
                            }}
                            shortenedLink={shortLink}
                            shortLinkData={{
                              url: selectedItem.data.link,
                              productId: selectedItem.productId,
                              shoppingItemId: selectedItem.id,
                              productImage: selectedItem.data.imageUrl,
                              productName: selectedItem.data.name,
                            }}
                            isLoggedIn
                          >
                            {(ref, url) => (
                              <>
                                <FacebookShareButton
                                  ref={ref}
                                  url={url}
                                  quote={t('createLink.socialMessage')}
                                  style={{
                                    display: 'none',
                                  }}
                                >
                                  <img
                                    src={facebookIcon}
                                    alt={t('imageAlts.facebook')}
                                    className={classes.socialIcon}
                                  />
                                </FacebookShareButton>
                                <img
                                  src={facebookIcon}
                                  alt={t('imageAlts.facebook')}
                                  className={classes.socialIcon}
                                />
                              </>
                            )}
                          </SocialShareLink>
                        </div>
                        <div
                          style={{ marginLeft: 10 }}
                          className={`${classes.socialButton} ${classes.socialShareButton}`}
                        >
                          <SocialShareLink
                            onInitial={() => {
                              setShortLink('')
                              setIsShortLinkLoading(true)
                            }}
                            onSuccess={data => {
                              setShortLink(data.shortUrl)
                              setIsShortLinkLoading(false)
                              handleUpdateShortLink(
                                selectedItem,
                                data.shortUrl,
                                data.tracking_url
                              )
                            }}
                            shortenedLink={shortLink}
                            shortLinkData={{
                              url: selectedItem.data.link,
                              productId: selectedItem.productId,
                              shoppingItemId: selectedItem.id,
                              productImage: selectedItem.data.imageUrl,
                              productName: selectedItem.data.name,
                            }}
                            isLoggedIn
                          >
                            {(ref, url) => (
                              <>
                                <TwitterShareButton
                                  ref={ref}
                                  url={url}
                                  title={t('createLink.socialMessage')}
                                  style={{
                                    display: 'none',
                                  }}
                                >
                                  <img
                                    src={twiterIcon}
                                    alt={t('imageAlts.twitter')}
                                    className={classes.socialIcon}
                                  />
                                </TwitterShareButton>
                                <img
                                  src={twiterIcon}
                                  alt={t('imageAlts.twitter')}
                                  className={classes.socialIcon}
                                />
                              </>
                            )}
                          </SocialShareLink>
                        </div>
                        <div
                          style={{ marginLeft: 10 }}
                          className={`${classes.socialButton} ${classes.socialShareButton}`}
                        >
                          <SocialShareLink
                            onInitial={() => {
                              setShortLink('')
                              setIsShortLinkLoading(true)
                            }}
                            onSuccess={data => {
                              setShortLink(data.shortUrl)
                              setIsShortLinkLoading(false)
                              handleUpdateShortLink(
                                selectedItem,
                                data.shortUrl,
                                data.tracking_url
                              )
                            }}
                            shortenedLink={shortLink}
                            shortLinkData={{
                              url: selectedItem.data.link,
                              productId: selectedItem.productId,
                              shoppingItemId: selectedItem.id,
                              productImage: selectedItem.data.imageUrl,
                              productName: selectedItem.data.name,
                            }}
                            isLoggedIn
                          >
                            {(ref, url) => (
                              <>
                                <PinterestShareButton
                                  ref={ref}
                                  url={trackingLinkNoRedirect}
                                  description={t('createLink.socialMessage')}
                                  media={
                                    selectedItem.data.imageUrl ||
                                    selectedItem.data.base64 ||
                                    printestDefaultImageURL
                                  }
                                  style={{
                                    display: 'none',
                                  }}
                                >
                                  <img
                                    src={pinterestIcon}
                                    alt={t('imageAlts.twitter')}
                                    className={classes.socialIcon}
                                  />
                                </PinterestShareButton>
                                <img
                                  src={pinterestIcon}
                                  alt={t('imageAlts.twitter')}
                                  className={classes.socialIcon}
                                />
                              </>
                            )}
                          </SocialShareLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </section>
        {/*</Modal>*/}
      </Dialog>
      {/* <Dialog
      fullScreen={false}
      open={openStatus}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
      maxWidth="lg"
    >
      {console.log(selectedItem)}
      
    </Dialog> */}
    </>
  )
}

export default withTranslation()(quickViewModal)
