import { makeStyles } from '@material-ui/core/styles'
import { COLORS, WIDTH_DIMENSIONS, BREAKPOINTS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  headingWrapper: {
    width: '100%',
    background: COLORS.whiteStain,
    padding: '40px 0px',
    marginBottom: 40,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 20px',
    },
  },
  headingContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: WIDTH_DIMENSIONS.max,
    margin: 'auto',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  headingTextContainer: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 10,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  headingLogoContainer: {
    display: 'flex',
  },
  headingLogoImage: {
    width: 100,
    height: 100,
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: 80,
      height: 80,
    },
  },
  headingLogoText: {
    paddingLeft: 21,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      overflowX: 'scroll',
    },
  },
  chirpyestPageTitle: {
    lineHeight: '34px',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      fontSize: '20px !important',
      lineHeight: '23px !important',
    },
  },
  chirpyestPageHeader: {
    lineHeight: '80px !important',
    fontSize: '80px !important',
    marginBottom: '25px !important',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: 777,
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      lineHeight: '50px !important',
      marginBottom: '0px !important',
      fontSize: '45px !important',
    },
  },
  description: {
    fontSize: '16px !important',
    lineHeight: '23px !important',
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 20,
      marginLeft: 0,
    },
  },
  actionButtonsContainer: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  filtersContainer: {
    display: 'flex',
    // when add refine change the 2 lines below
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  filterButton: {
    width: 'calc(50% - 10px)',
  },
  productsContainer: {
    fontFamily: FONTS.Graphik.GraphikLight,
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '90%',
    },
  },
  countingFilterContainer: {
    display: 'flex',
    marginBottom: 20,
    paddingRight: 10,
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  itemsCount: {
    color: COLORS.ashGrey,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '1rem',
    letterSpacing: '1px',
    lineHeight: '18p',
    textTransform: 'uppercase',
  },
  joinCard: {
    backgroundColor: COLORS.whiteStain,
    height: 454,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    display: 'flex',
    alignItems: 'center',
  },
  joinCardText: {
    fontSize: '3.22rem',
    textAlign: 'center',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  btn: {
    background: 'white',
    color: theme.palette.common.black,
    transition: '0.5s',
    maxWidth: 300,
    margin: '10px 10px 0 auto',
    ['&:focus']: {
      outline: 'none',
    },
    ['& > p']: {
      color: theme.palette.common.black,
    },
    ['&:hover']: {
      color: theme.palette.common.white,
      background: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      ['& > p']: {
        color: theme.palette.common.white,
      },
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: '100%',
    },
  },
  btnOther: {
    ['& > p']: {
      padding: '0 20px',
      width: '100%',
      justifyContent: 'center',
    },
    ['&:hover']: {
      ['& > img']: {
        filter: 'invert(100%)',
      },
    },
  },
  forwardedFolderLink: {
    position: 'relative',
    maxWidth: 400,
    marginLeft: 'auto',
    ['& > input']: {
      color: theme.palette.common.black,
      letterSpacing: 1.2,
      textAlign: 'center',
      background: 'white',
      border: `1px solid ${COLORS.lightGrey}`,
    },
    ['& > button']: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100px',
      margin: 0,
    },
  },
}))
