import { Box, BoxProps } from '@material-ui/core'
import React from 'react'
import { styles } from './styles'

export default function OpenIosApp({ sx }: { sx?: BoxProps['sx'] }) {
  const classes = styles()

  const userId = window.localStorage.getItem('user_id') ?? null

  const profile = window.localStorage.getItem('profile') ?? null

  const username = profile !== null ? JSON.parse(profile).username : null

  const openChirpyestApplication = () => {
    window.location.href = `ChirpyestApp://open?username=${username}&userId=${userId}`
  }

  return (
    <Box sx={{ mt: 5, ...sx }}>
      <button
        className={classes.button}
        onClick={() => openChirpyestApplication()}
      >
        open the app
      </button>
    </Box>
  )
}
