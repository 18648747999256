import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../../../appState'
import closeIcon from '../../../assets/images/close.svg'
import axios from '../../../axios'
import { ENDPOINTS } from '../../../constants'
import MyPreview from '../../cardPreview'
import ChooseImage from '../../ChooseImageDialog'
import CHRSpinner from '../../spinner'
import QuickViewModal from '../quickviewModal'
import CHRcard from './card'
import { styles } from './styles'
interface GridItemsProps {
  t: TFunction
  shoppingUserProfile: any
  shoppingBoardItems: any[]
  handleRemoveItem: any
  handleUpdatePosition: any
  canEdit: boolean
  selectedPage: number
  selectedSort: any
  isLoadMore?: boolean
  handleUpdateShortLink: Function
  isFolder: boolean
}

function array_move(arr, old_index, new_index) {
  const copy = JSON.parse(JSON.stringify(arr))
  copy.splice(new_index, 0, copy.splice(old_index, 1)[0])
  return copy
}

const GridItemsStatic = (props: GridItemsProps) => {
  const classes = styles()
  const [appState] = useAppState()

  const {
    shoppingUserProfile,
    shoppingBoardItems,
    handleRemoveItem,
    handleUpdatePosition,
    canEdit,
    selectedPage = 1,
    selectedSort = 1,
    isLoadMore = false,
    handleUpdateShortLink,
    showSelect,
    onCheckFolder,
    isFolder = false,
  } = props

  const [isModal, setIsModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [renderedProducts, setRenderedProducts] = useState(shoppingBoardItems)
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showImagsModal, setShowImagesModal] = useState(false)
  const [carouselImages, setCarouselImages] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  const [showImages, setShowImages] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const closeModal = () => {
    setSelectedItem(null)
    setIsModal(false)
  }

  useEffect(() => {
    setRenderedProducts(shoppingBoardItems)
  }, [shoppingBoardItems])

  const handleUpdateImage = async (updatedImg: string) => {
    try {
      setLoadingImages(true)
      setShowImages(false)
      await axios.post(
        `/api/v1/shopping-board-items/${selectedItem.id}/update-item-image`,
        {
          data: { ...selectedItem.data, imageUrl: selectedImage },
        }
      )
      handleChangeImage(selectedItem.id, selectedImage)
      setLoadingImages(false)
      setShowImagesModal(false)
    } catch (e) {
      setLoadingImages(false)
    }
  }

  const handleClick = (data: any) => {
    if (data.type === 'instagram') {
      window.open(data.data.link, '_blank')
      // } else if (canEdit) {
    } else {
      setSelectedItem(data)
      setIsModal(true)
    }
    // } else {
    //   const link = generateTrackingLink(
    //     {
    //       network: data.data.networkId,
    //       trackingLink: data.data.tracking_url,
    //       url: data.data.link,
    //       brandName: data.data.brandName,
    //       commission: data.data.cashBack,
    //     },
    //     {
    //       userId: `${data.userId || 0}`,
    //       userName: appState.userProfile?.userName,
    //     },
    //     data.productId,
    //     data.id,
    //     canEdit
    //   )
    //   window.open(link, '_blank')
    //   // we don't want to wait for this api call
    //   axios
    //     .post(
    //       `/api/v1/shopping-board-items/${selectedItem.id}/update-clicks-count`
    //     )
    //     .catch(() => {})
    // }
  }

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    setRenderedProducts(array_move(renderedProducts, dragIndex, hoverIndex))
  }

  const handleChangeImage = (id: number, imageUrl: string) => {
    const updatedProducts = renderedProducts.map((product: any) => {
      if (product.id === id) {
        const updatedItem = { ...product, data: { ...product.data, imageUrl } }
        setSelectedItem({ ...updatedItem })
        return updatedItem
      } else return product
    })
    setRenderedProducts([...updatedProducts])
  }

  const handleChoice = async product => {
    try {
      setCarouselImages([])
      setLoadingImages(true)
      setShowImages(true)
      setIsLoading(true)
      setShowImagesModal(true)
      let {
        data: { data },
      } = await axios.post(ENDPOINTS.getProductAllimages, {
        productLink: product.data.link,
      })

      data = data.filter((a: string) => !a.startsWith('data:'))

      setCarouselImages(data)
      setLoadingImages(false)
      if (data.length === 0) {
        setShowImages(false)
      }
      setSelectedItem(product)
      setIsLoading(false)
    } catch (err) {
      setLoadingImages(false)
      setShowImages(false)
    }
  }
  const handleGridClickButton = product => {
    if (!loadingImages) handleChoice(product)
  }

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={isPhone ? 2 : 3}>
        {renderedProducts.map((elGrid, i) => {
          if (
            elGrid.page === selectedPage ||
            (isLoadMore && elGrid.page <= selectedPage)
          ) {
            return (
              <CHRcard
                key={elGrid.id}
                index={i}
                id={isFolder ? elGrid.folderItemId : elGrid.id}
                moveCard={moveCard}
                product={elGrid}
                handleUpdatePosition={handleUpdatePosition}
                canEdit={canEdit}
                onCardClick={handleClick}
                removeItem={handleRemoveItem}
                showSelect={showSelect}
                onCheck={onCheckFolder}
                isFolder={isFolder}
                shoppingUserProfile={shoppingUserProfile}
                onGridClick={handleGridClickButton}
              />
            )
          }
        })}
      </Grid>
      {isModal && (
        <QuickViewModal
          handleClose={closeModal}
          openStatus={isModal}
          selectedItem={selectedItem}
          canEdit={canEdit}
          handleUpdateShortLink={handleUpdateShortLink}
          handleChangeImage={handleChangeImage}
        />
      )}
      {showImagsModal && (
        <Dialog
          fullScreen={fullScreen}
          open={showImagsModal}
          onClose={() => {
            setShowImagesModal(false)
          }}
          aria-labelledby="quickview"
          className={classes.imageModal}
        >
          <DialogTitle>
            <span
              className={classes.closeButton}
              onClick={() => {
                setShowImagesModal(false)
              }}
            >
              {closeIcon && (
                <img
                  src={closeIcon}
                  className={classes.btnIcon}
                  alt="buttonIcon"
                />
              )}
            </span>
          </DialogTitle>
          <DialogContent>
            {isLoading ? (
              <CHRSpinner />
            ) : (
              <ChooseImage
                currentImage={
                  selectedItem.data.imageUrl || selectedItem.data.base64
                }
                images={carouselImages}
                setSelectedImage={setSelectedImage}
              />
            )}
          </DialogContent>

          <DialogActions>
            <button
              className={classes.updateImageButton}
              onClick={() => handleUpdateImage(selectedImage)}
            >
              update
            </button>
          </DialogActions>
        </Dialog>
      )}
      <MyPreview />
    </div>
  )
}
export default withTranslation()(GridItemsStatic)
