import React from 'react'
import { makeStyles, Modal } from '@material-ui/core'
import { FONTS, ROUTES } from '../../constants'
import { navigate } from 'gatsby'
import CHRButton from '../button'
import chirpLogo from '../../assets/images/chirpyest-text-logo.svg'

interface RegisterToEarnCashbackModalProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
  brandName: string
  commission: string
}
const RegisterToEarnCashbackModal = (
  props: RegisterToEarnCashbackModalProps
) => {
  const classes = styles()
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className={classes.modal}>
        <img src={chirpLogo} alt="logo" className={classes.logo} />
        <div className={classes.modalContainer}>
          <div style={{ flex: 1 }}>
            <p style={{ fontFamily: FONTS.Graphik.GraphikLight, height: 80 }}>
              earn up to {props.commission} cashback from {props.brandName}
            </p>
            <CHRButton
              onClick={() => navigate(ROUTES.joinWithModal)}
              label="join chirpyest - it’s free"
            />
          </div>
          <div style={{ flex: 1 }}>
            <p style={{ fontFamily: FONTS.Graphik.GraphikLight, height: 80 }}>
              no, i want to go straight to {props.brandName} to shop
            </p>
            <CHRButton
              label={'shop ' + props.brandName}
              onClick={props.onContinue}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const styles = makeStyles(theme => ({
  modal: {
    maxWidth: 800,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: 20,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 80,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 60,
  },
  logo: {
    width: '136px',
    height: '35px',
  },
}))

export default RegisterToEarnCashbackModal
