import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  modalRoot: {},
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
  },

  closeButton: {
    position: 'absolute',
    top: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },

  dialogTitleSection: {
    position: 'relative',
    // padding: 0,
    // marginTop: 60,
    // marginBottom: 60,
    // ['& > h2']: {
    //   textAlign: 'center',
    // },
  },
  card: {
    boxShadow: 'none',
  },
  cardArea: {
    display: 'flex !important',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    ['&:hover .MuiCardActionArea-focusHighlight']: {
      opacity: 0,
    },
  },
  cardMediaWrapper: {
    width: '100%',
    alignSelf: 'stretch',
    position: 'relative',
  },
  cardMedia: {
    display: 'block',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    maxHeight: 700,
    objectFit: 'contain',
  },
  boxInfo: {
    padding: '23px 0 0',
    margin: '0 0 13px',
  },
  cardName: {
    fontFamily: `${FONTS.Schnyder.SchnyderXLLight} !important`,
    fontSize: '2.5rem !important',
    lineHeight: '1.125 !important',
    textTransform: 'lowercase',
  },
  boxPrices: {
    float: 'left',
    width: '100%',
    padding: '0 0 40px',
    borderBottom: '1px solid #000',
    margin: '0 0 40px',
  },
  cardBrandName: {
    fontFamily: `${FONTS.Schnyder.SchnyderXLLight} !important`,
    fontSize: '1.875rem !important',
    lineHeight: '1.13 !important',
    textTransform: 'lowercase',
    float: 'left',
  },
  cardBrandNameText: {
    boxShadow: 'inset 0 -2px white, inset 0 -3px #000',
  },
  cardPrice: {
    fontFamily: `${FONTS.Schnyder.SchnyderXLLight} !important`,
    fontSize: '1.875rem !important',
    lineHeight: '1.13 !important',
    textTransform: 'lowercase',
    float: 'right',
  },
  cardContent: {
    width: '100%',
    padding: '16px 20px',
  },
  igBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    padding: '25px 0 40px',
    margin: '0 0 40px',
  },
  igBoxMediaWrap: {
    width: 80,
  },
  igBoxMedia: {},
  igBoxInfo: {
    padding: '0 0 0 15px',
  },
  igBoxText: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.875rem',
    lineHeight: '1.1',
  },
  shareBox: {
    margin: '0 0 50px',
  },
  formShare: {
    margin: '0 0 40px',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 10,
    width: '100%',
  },
  input: {
    border: '2px solid #E3E6E6',
    background: 'none',
    padding: '10px 20px',
    height: '55px',
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '1.2px',
    borderRight: 0,
  },
  btnCopy: {
    width: 140,
    margin: 0,
    outline: 'none',
    ['&:hover']: {
      background: COLORS.watermelonPink,
      borderColor: COLORS.watermelonPink,
    },
  },
  convertedLink: {},
  shopLinkWrap: {},
  shopLinkBtn: {
    width: '100%',
    border: '2px solid #000',
    cursor: 'pointer',
    height: '54px',
    margin: '5px auto 10px',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    transition: '0.5s',
    alignItems: 'center',
    fontFamily: FONTS.Graphik.GraphikMedium,
    letterSpacing: '1.2px',
    justifyContent: 'center',
    backgroundColor: '#000',
    textDecoration: 'none',
    color: '#fff',
    ['&:hover']: {
      background: COLORS.watermelonPink,
      borderColor: COLORS.watermelonPink,
    },
  },
  cashPercentage: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '1rem',
    lineHeight: '1.44',
    marginBottom: 20,
  },
  viaSocials: {},
  viaShort: {
    textAlign: 'center',
    fontSize: '1rem !important',
    fontFamily: `${FONTS.Graphik.GraphikLight} !important`,
    lineHeight: '1.69',
    marginBottom: `15px !important`,
  },
  viaLinks: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
  },
  socialButton: {
    padding: '0 10px',
    margin: '0 0 10px',
    [' & > div > button']: {
      borderRadius: '50%!important',
      width: 50,
      height: 50,
      display: 'block',
      border: '2px solid #000!important',
      overflow: 'hidden',
      padding: '12px!important',
      outline: 'none',
      background: '#fff',
      cursor: 'pointer',
    },
    [' & img']: {
      maxWidth: '100%',
    },
  },
  socialIcon: {
    width: '100%',
    maxWidth: '24px !important',
  },
  socialShareButton: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    border: '2px solid black',
    borderRadius: 999,
  },
  smsButton: {},
  [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
    cardContent: {
      padding: '77px 130px 20px !important',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
    modal: {
      maxWidth: 1254,
    },
    cardMediaWrapper: {
      width: '50%',
    },
    cardContent: {
      width: '50%',
      padding: '55px 60px 0 !important',
    },
  },
  disabledBtn: {
    backgroundColor: COLORS.whiteStain,
    borderColor: COLORS.whiteStain,
    color: COLORS.black,
    '&:hover': {
      backgroundColor: COLORS.whiteStain,
      borderColor: COLORS.whiteStain,
    },
  },
  errImage: {
    width: 200,
    height: '100%',
    minHeight: 648,
  },
  choiceButton: {
    position: 'absolute',
    bottom: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  updateImageButton: {
    color: COLORS.white,
    backgroundColor: COLORS.black,
    padding: '10px',
    zIndex: 10,
  },
  loadingImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  imageWrapper: {
    height: '100%',
  },
  errMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
}))
